// main bundle always contains references to dynamically imported modules across entire application,
// therefore if a PageView was to be changed, the main bundle would also be rebuilt so it can reference new filename hash.
// for this reason we want to make sure the main bundle is very lightweight, as it will change frequently.

(async () => {
    // deliberately dynamically import PagesApp so webpack creates a new bundle,
    // this allows us to have a separate heavy weight PagesApp which changes infrequently.
    const loadApp = (await import(
        /* webpackPreload: true */
        /* webpackFetchPriority: "high" */
        './app/PagesApp'
    )).default;
    await loadApp();
})();